<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-09 16:28:41
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:02:51
 * @Description: 货品分类管理
 * @FilePath: \src\views\App\Iemma\CommodityClassManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreateCommodityClassModel()"
        >创建货品分类</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 分类名称 -->
      <el-table-column
        label="分类名称"
        prop="name"
        align="center"
        width="300px"
      ></el-table-column>

      <!-- 备注 -->
      <el-table-column label="备注">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 添加时间 -->
      <el-table-column
        label="创建时间"
        prop="add_time"
        align="center"
        width="140px"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateCommodityClassModel(s.row.kcoc_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelCommodityClass(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[20, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-commodity-class ref="nu-commodity-class"></nu-commodity-class>
  </div>
</template>

<script>
import NuCommodityClass from "./NUCommodityClass";
import { getCommodityClassTable, delCommodityClass } from "./api"; //页面专有接口
export default {
  name: "CommodityClassManage",
  components: {
    NuCommodityClass,
  },
  data() {
    return {
      p: {
        pageNo: 1, //当前页
        pageSize: 20, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  methods: {
    //加载表格数据
    _Load() {
      getCommodityClassTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 180002) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开货品分类添加组件
    _OpenCreateCommodityClassModel() {
      this.$refs["nu-commodity-class"].open(0);
    },

    //打开货品分类修改组件
    _OpenUpdateCommodityClassModel(id) {
      this.$refs["nu-commodity-class"].open(id);
    },

    //删除
    _DelCommodityClass(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除货品分类【" +
          data.name +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delCommodityClass({ kcoc_id: data.kcoc_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  mounted() {
    this._Load();
  },
};
</script>
